export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を保存"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存中..."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本気ですか？"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDをコピーする"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["今日の ", _interpolate(_list(0))])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["昨日の ", _interpolate(_list(0))])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このチャンネルで話す権限がありません。"])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続されていません"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージを添付する"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージを入力してください"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後に戻る"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいメッセージ"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圧縮する"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード先"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィールを見る"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割の編集"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キック"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁止"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーのコピー:タグ"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引用"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既読としてマーク"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバー設定"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーから離れる"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルをミュートする"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルのミュートを解除する"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["絵文字の管理"])},
      "call-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話オプション"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["起動オプション"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プログラム活動"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットの管理"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インタフェース"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの削除"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更履歴"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIP の機能"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィールを編集する"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを変更する"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleドライブを再リンクする"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleドライブをリンクする"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳しいプロフィール"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この情報は誰でも見ることができます。"])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知設定を変更します。"])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知音"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クールな音を出して注目を集めます"])},
      "notification-mentions-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンション/ダイレクトメッセージのみ"])},
      "notification-mentions-only-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンションとダイレクトメッセージのみが音を鳴らしてプッシュされるようにします。"])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プッシュ通知"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アプリを閉じているときでもプッシュ通知を受け取ります。"])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["独自のかわいい絵文字を無料でアップロードしてください!"])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サポートされているファイルの種類は、png、jpg、gif です。"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["絵文字を追加する"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デスクトップ アプリの起動オプション"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらの設定を変更するには、Nertivia デスクトップ アプリをダウンロードする必要があります。"])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["起動時にnertiviaを開く"])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下にアプリを追加して、実行中のプログラムやゲームを共有します。"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プログラム アクティビティ機能を使用するには、Nertivia デスクトップ アプリをダウンロードする必要があります。"])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット ユーザーを作成すると、API を使用して独自の派手なボットを作成できるようになります。"])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット コマンドを追加して、ボットにどのコマンドが存在するかをユーザーに簡単に知らせます"])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットの作成"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コマンドの追加"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットの編集"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集コマンド"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待リンクの作成"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待URLをコピー"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークンの管理"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークンの表示"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークンのコピー"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークンのリセット"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットの削除"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを削除しようとしています"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["何が削除されるのか"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除されないもの"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージが公開サーバーに送信される"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要件"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのサーバーを残す/削除する"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのボットを削除する"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私のアカウントを削除する"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語を変更した後、リロードが必要になる場合があります。 "])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻訳者"])}
    },
    "interface": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ナーティビアの外観を変更します。"])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["色"])},
      "time-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間の形式"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デフォルトのテーマ"])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOLEDダーク"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハロウィン 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事前定義されたテーマ"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムCSS"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般的な"])},
      "navigation-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ナビゲーションバー"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未完成または実験的な機能を有効にします。"])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在利用できる WIP 機能はありません。"])}
    }
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["権限"])},
      "admin": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての権限を有効にします"])}
      },
      "send-messages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージを送信する"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーチャネルにメッセージを送信する"])}
      },
      "manage-roles": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割の管理"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロールを更新または削除する"])}
      },
      "manage-channels": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネルの管理"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルを更新または削除する"])}
      },
      "kick-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キック"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーをキックする"])}
      },
      "ban-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁止"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの参加をキックして制限する"])}
      }
    },
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般的な"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネルの管理"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割の管理"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待の管理"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーの可視性"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["絵文字の管理"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知の管理"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁止されたユーザー"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの管理"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーの削除"])}
    },
    "manage-roles": {
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分の役割を管理します。"])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デフォルトの役割"])},
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットの役割"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい役割の作成"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このロールはボットによって作成されたため、削除したり、別のメンバーに割り当てたりすることはできません。"])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このロールはすべてのメンバーに適用されるデフォルトのロールであるため、削除したり割り当てを解除したりすることはできません。"])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割の色を選択してください"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割名"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割の削除"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割を削除しています..."])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割を非表示にする"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般的なサーバー設定"])}
    },
    "manage-channels": {
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルを管理します。"])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルの削除"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルを削除しています..."])},
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネル名"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これはデフォルトのチャンネルです。 "])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規作成"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージを送信する"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レート制限 (秒)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキストチャンネル"])},
      "category-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML チャネル (WIP)"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここからメンバーの参加禁止を解除する"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁止解除中..."])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁止を解除する"])}
    },
    "manage-invites": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者のみが全員の招待状を表示できます。"])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待状の作成"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタム招待"])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムリンクを保存"])},
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待状の作成"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンク"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリエイター"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用途"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの検索"])}
    },
    "delete-server": {
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認のためにサーバー名を入力します"])},
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除中..."])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知設定を変更します。"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらの設定はあなたにのみ適用され、他の人には影響しません。"])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知を有効にする"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音を消す"])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知を無効にする"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " が入力中です..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " と ", _interpolate(_list(1)), " が入力中です..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "、", _interpolate(_list(1)), "、", _interpolate(_list(2)), " が入力中です..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 人が入力しています..."])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存されたメモ"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達を追加"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィール"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリックしてカスタム ステータスを追加します"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部追いついた！"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダイレクトメッセージ"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバー"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探検する"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダッシュボード"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー名とタグを入力して友達を追加します。"])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加中..."])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーの追加"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待コードを入力してサーバーに参加します。"])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["独自のサーバーを作成します。"])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加する"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成する"])}
    }
  },
  "presence": {
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オフライン"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンライン"])},
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["離れて"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忙しい"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遊びたい"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["メンバー (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["オフライン (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共通の友達"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バッジ"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加しました"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一時停止中"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達を削除する"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエストを受け入れる"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルリクエスト"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達を追加"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージを送信する"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共通サーバー"])}
  },
  "connection": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["準備ができて！"])},
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証中..."])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップデート"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用可能なアップデート"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピー"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消去"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピーしました！"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])}
  },
  "messages": {
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーを離れました"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蹴られた"])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁止されています"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーに参加しました！"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーに接続できませんでした。"])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レート制限モード"])}
  }
}